import axios from "axios";

const PRODUCTION = true;

export const API_URL = PRODUCTION
  ? "https://auditsafeapi.impulsion.in/"
  : "http://127.0.0.1:8000/";

// "http://127.0.0.1:8000"
// "ws://127.0.0.1:8000"
// "https://api.elemzy.com"
// "wss://api.elemzy.com"

export const alertMessage = (type, title, message) => {
  return {
    type: "ALERT",
    payload: { type, title, message },
  };
};

export const loadUser = () => (dispatch, getState) => {
  var state = getState();
  axios
    .get(API_URL + "api/auth/user", {
      headers: {
        Authorization: state.auth.token,
      },
    })
    .then((res) => {
      dispatch({
        type: "USER_LOADED",
        payload: res.data,
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: "AUTH_ERROR",
      });
    });
};

// LOGIN USER
export const login = (email, password) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  var fireToken = "web";

  // Request Body
  const body = JSON.stringify({ email, password, fireToken });

  return axios
    .post(API_URL + "api/auth/login", body, config)
    .then((res) => {
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: res.data,
      });

      dispatch(loadUser());
      dispatch(alertMessage("SUCCESS", "Welcome!", "Login success."));
    })
    .catch((err) => {
      dispatch(
        alertMessage("ERROR", "Error", err.response.data.non_field_errors[0])
      );
      dispatch({
        type: "LOGIN_FAIL",
      });
    });
};

export const sendReset = (email) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Request Body
  const body = JSON.stringify({ email });

  axios
    .post(API_URL + "api/password_reset/", body, config)
    .then((res) => {
      dispatch(
        alertMessage(
          "SUCCESS",
          "Email Sent.",
          "Check your email account for password reset link."
        )
      );
    })
    .catch((err) => {
      dispatch(
        alertMessage(
          "ERROR",
          err.response.status,
          Object.values(err.response.data)[0][0]
        )
      );
    });
};

export const passwordReset = (token, password) => (dispatch) => {
  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Request Body
  const body = JSON.stringify({ token, password });

  axios
    .post(API_URL + "api/password_reset/confirm/", body, config)
    .then((res) => {
      dispatch({
        type: "PASSWORD_RESET",
        payload: res.data,
      });
      dispatch(
        alertMessage("SUCCESS", "Success!", "Password was reset successfully.")
      );
    })
    .catch((err) => {
      var errString = "";
      for (var i = 0; i < Object.keys(err.response.data).length; i++) {
        for (
          var j = 0;
          j < err.response.data[Object.keys(err.response.data)[i]].length;
          j++
        ) {
          errString +=
            err.response.data[Object.keys(err.response.data)[i]][j] + "\n";
        }
      }
      dispatch(alertMessage("ERROR", err.response.status, errString));
    });
};

export const logout = () => (dispatch, getState) => {
  var state = getState();

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  config.headers["Authorization"] = state.auth.token;

  axios
    .post(API_URL + "api/auth/logout", null, config)
    .then(() => {
      dispatch(
        alertMessage("SUCCESS", "Logout success.", "You have been logged out.")
      );
      dispatch({ type: "LOGOUT_SUCCESS" });
    })
    .catch((err) => {
      dispatch(
        alertMessage("SUCCESS", "Logout success.", "You have been logged out.")
      );
      dispatch({ type: "AUTH_ERROR" });
    });
};

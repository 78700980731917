const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  isLoading: true,
  user: null,
  loginFailed: false,
  entryPage: "/",
  alert: null,
  isMobile: window.innerWidth <= 1300 ? true : false,
};

export default function (state = initialState, action) {
  console.log("-------------");
  console.log(action);
  console.log("-------------");

  switch (action.type) {
    case "USER_LOADING":
      return {
        ...state,
        token: localStorage.getItem("manager_token"),
      };
    case "USER_LOADED":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        isLoading: false,
      };

    case "LOGIN_SUCCESS":
      localStorage.setItem("manager_token", "Token " + action.payload.token);
      return {
        ...state,
        token: "Token " + action.payload.token,
        loginFailed: false,
      };
    case "LOGOUT_SUCCESS":
      localStorage.removeItem("manager_token");
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: true,
        message: null,
        loginFailed: true,
      };
    case "PASSWORD_RESET":
      localStorage.removeItem("manager_token");
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: true,
        message: null,
        loginFailed: true,
      };

    case "AUTH_ERROR":
      localStorage.removeItem("manager_token");
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: true,
        message: null,
        loginFailed: true,
      };
    case "RESET_REDIRECT_LOGIN":
      return {
        ...state,
        loginFailed: false,
      };
    case "ALERT":
      return {
        ...state,
        alert: {
          title: action.payload.title,
          message: action.payload.message,
          type: action.payload.type,
        },
      };
    case "MOBILE_VIEW":
      return {
        ...state,
        isMobile: action.payload,
      };
    case "REMOVE_ALERT":
      return {
        ...state,
        alert: null,
      };

    case "ENTRY_PAGE":
      return {
        ...state,
        entryPage: action.payload,
      };
    case "REFRESH_DATA":
      return {
        ...state,
        refresh: action.payload,
      };
    default:
      return state;
  }
}

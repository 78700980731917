import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import moment from "moment";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { alertMessage } from "../actions/auth";
import { Bar } from "react-chartjs-2";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Chart } from "chart.js/auto";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Fab } from "@mui/material";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Checkbox from "@mui/material/Checkbox";
import DownloadIcon from "@mui/icons-material/Download";
import QrCodeIcon from "@mui/icons-material/QrCode";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import CloseIcon from "@mui/icons-material/Close";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Criteria from "../components/Criteria";
import imageCompression from "browser-image-compression";

import { API_URL } from "../actions/auth";

import { themeStyle, themeStyleDark } from "../components/Components";
const ExcelJS = require("exceljs");

function ViewEntry() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);

  const [messes, setMesses] = useState("");
  const [messData, setMessData] = useState([]);
  const [currentData, setCurrentData] = useState({});

  const [messIndex, setMessIndex] = useState(0);
  const [criteriaList, setCriteriaList] = useState([]);
  const [criteria, setCriteria] = useState([]);
  const [random, setRandom] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFilesPaths, setSelectedFilesPaths] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [messDates, setMessDates] = useState([]);
  const [dateIndex, setDateIndex] = useState(0);

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function getTotalScore(category) {
    var criKeys = Object.keys(criteria);
    var total = 0;
    for (var i = 0; i < criKeys.length; i++) {
      if (criteria[criKeys[i]]["category"] == category) {
        if (currentData[criKeys[i]].length != 0) {
          total += parseInt(currentData[criKeys[i]]);
        }
      }
    }
    return total;
  }

  function getTotalMaxScore(category) {
    var criKeys = Object.keys(criteria);
    var total = 0;
    for (var i = 0; i < criKeys.length; i++) {
      if (criteria[criKeys[i]]["category"] == category) {
        total += parseInt(criteria[criKeys[i]]["max_score"]);
      }
    }
    return total;
  }

  function getTotalMaxScoreAll() {
    var criKeys = Object.keys(criteria);
    var total = 0;
    for (var i = 0; i < criKeys.length; i++) {
      total += parseInt(criteria[criKeys[i]]["max_score"]);
    }
    return total;
  }

  function getTotal() {
    var criKeys = Object.keys(criteria);
    var total = 0;
    for (var i = 0; i < criKeys.length; i++) {
      if (currentData[criKeys[i]].length != 0) {
        total += parseInt(currentData[criKeys[i]]);
      }
    }
    return total;
  }

  function getData() {
    var body = {
      email: state.auth.user.email,
    };
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };
    axios
      .post(API_URL + "get_criteria", JSON.stringify(body), config)
      .then((res) => {
        setCriteria(res.data["criteria"]);
        setCriteriaList(res.data["criteria_list"]);

        var tempData = {};
        var criKeys = Object.keys(res.data["criteria"]);

        for (var i = 0; i < criKeys.length; i++) {
          tempData[criKeys[i]] = {};
          tempData[criKeys[i]] = "1";
          tempData[criKeys[i] + "remarks"] = "";
        }
        axios.post(API_URL + "get_messes", body, config).then((res) => {
          setMesses(res.data);
          getMessData(res.data[0]["mess_id"]);
        });
      });
  }

  function getMessData(mess_id) {
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };
    axios
      .post(
        API_URL + "get_data",
        JSON.stringify({ email: state.auth.user.email, mess_id: mess_id }),
        config
      )
      .then((res) => {
        setMessData(res.data);
        setCurrentData(res.data[0]);
        setIsLoading(false);
        var tempDates = [];
        for (var i = 0; i < res.data.length; i++) {
          tempDates.push(res.data[i]["date"]);
        }
        setMessDates(tempDates);
      });
  }

  if (state.auth.refresh == "REFRESH_DATA") {
    if (state.auth.user.is_staff || state.auth.user.is_manager) {
      getData();
    }
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      if (state.auth.user.is_staff || state.auth.user.is_manager) {
        getData();
      }
    }
  }, []);

  function getMessList() {
    var messess = Object.keys(messes).map((mess, index) => (
      <MenuItem value={index}>{messes[index]["name"]}</MenuItem>
    ));
    return messess;
  }

  function getDatesList() {
    var datess = messDates.map((dat, index) => (
      <MenuItem value={index}>{dat}</MenuItem>
    ));
    return datess;
  }

  function handleMessChange(e) {
    setMessIndex(e.target.value);
  }

  function handleDateChange(e) {
    setDateIndex(e.target.value);
    setCurrentData(messData[e.target.value]);
  }

  const CriteriaTitle = () => {
    return (
      <div
        style={{
          marginTop: "25px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ width: "60%", fontSize: "30px" }}></div>

        <div
          style={{
            width: "20%",
            fontSize: "22px",
            textAlign: "center",
          }}
        >
          Max Scr
        </div>
        <div
          style={{
            width: "20%",
            fontSize: "22px",
            textAlign: "center",
          }}
        >
          Obt. Scr
        </div>
      </div>
    );
  };
  function getContent() {
    return (
      <Grid
        container
        spacing={5}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
      >
        <Grid
          item
          md={8}
          xs={12}
          style={{ paddingRight: state.auth.isMobile ? 0 : "20px" }}
        >
          <span
            style={{
              color: themeStyle.primaryColor,
              fontSize: "30px",
            }}
          >
            Audit Questions
          </span>
          {criteriaList.map((category, index) => (
            <Accordion
              style={{
                marginTop: "30px",
                borderRadius: "15px",
              }}
            >
              <AccordionSummary
                style={{
                  padding: "10px 25px 10px 25px",
                  backgroundColor: themeStyle.primaryButtonColor,
                  borderRadius: "15px",
                  fontWeight: 600,
                  color: "white",
                  fontSize: "25px",
                }}
                expandIcon={
                  <ExpandMoreIcon
                    style={{ fontSize: "50px", color: "white" }}
                  />
                }
                aria-controls="panel1-content"
                id="panel1-header"
              >
                {category}
              </AccordionSummary>

              <AccordionDetails
                style={{
                  borderBottomLeftRadius: "15px",
                  borderBottomRightRadius: "15px",
                }}
              >
                {Object.entries(criteria).map(([key, value]) =>
                  value["category"] == category ? (
                    <Fragment>
                      <CriteriaTitle />

                      <Fragment>
                        <div
                          style={{
                            marginTop: "25px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "60%" }}>
                            <span
                              style={{
                                color: themeStyle.primaryColor,
                                fontWeight: "bold",
                                fontSize: "20px",
                              }}
                            >
                              Criteria {key}
                            </span>
                            <br />
                            <span style={{ fontSize: "22px" }}>
                              {value["criteria"]}
                            </span>
                          </div>
                          <div
                            style={{
                              width: "20%",
                              fontSize: "22px",
                              textAlign: "center",
                            }}
                          >
                            {value["max_score"]}
                          </div>
                          <div
                            style={{
                              width: "20%",
                              fontSize: "22px",
                              textAlign: "center",
                            }}
                          >
                            {currentData[key]}
                          </div>
                        </div>
                        <div
                          style={{
                            color: "blue",
                            fontWeight: "bold",
                            fontSize: "20px",
                          }}
                        >
                          {currentData[key + "remarks"]}
                        </div>
                      </Fragment>
                    </Fragment>
                  ) : (
                    <Fragment />
                  )
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
        <Grid
          item
          md={4}
          style={{
            borderLeft: "2px solid " + themeStyle.primaryColor,
            backgroundColor: "white",
            paddingRight: "50px",
            height: "92vh",
            width: "110vw",
          }}
        >
          <span style={{ color: themeStyle.primaryColor, fontWeight: "bold" }}>
            Select Entity
          </span>
          <Select
            style={{
              backgroundColor: "white",
              marginTop: "2px",
              height: "55px",
              marginBottom: "5px",
              fontSize: "22px",
            }}
            variant={"outlined"}
            fullWidth
            value={messIndex}
            onChange={(e) => handleMessChange(e)}
          >
            {getMessList()}
          </Select>
          <Select
            style={{
              backgroundColor: "white",
              marginTop: "2px",
              height: "55px",
              marginBottom: "5px",
              fontSize: "22px",
            }}
            variant={"outlined"}
            fullWidth
            value={dateIndex}
            onChange={(e) => handleDateChange(e)}
          >
            {getDatesList()}
          </Select>
          <div
            style={{
              width: "100%",
              height: "2px",
              backgroundColor: "#ddd",
              marginTop: "25px",
            }}
          ></div>
          <div
            style={{
              fontSize: "40px",
              marginTop: "15px",
              color: "blue",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span> Total Score</span>
            <span>{getTotal()}</span>
          </div>
          {criteriaList.map((category, index) => (
            <div
              style={{
                marginTop: "25px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: "22px" }}>{category}</span>
              <div style={{ fontSize: "20px", color: "blue" }}>
                {getTotalScore(category)}
              </div>
            </div>
          ))}

          <Button
            style={{
              height: "46px",
              margin: "25px 0px 0px 0px",
              backgroundColor: themeStyle.primaryColor,
              boxShadow: "none",
            }}
            variant="contained"
            onClick={async () => {
              var email = currentData["email"];
              var name = currentData["name"];
              var datto = currentData["date"];

              delete currentData["email"];
              delete currentData["name"];
              delete currentData["date"];
              delete currentData["files"];

              const workbook = new ExcelJS.Workbook();
              workbook.creator = "Hydrolabs";
              const sheet = workbook.addWorksheet("Sheet 1");

              sheet.columns = [
                { header: "", key: "a", width: 10 },
                { header: "", key: "b", width: 30 },
                { header: "", key: "c", width: 50 },
                { header: "", key: "d", width: 10 },
                { header: "", key: "e", width: 20 },
                { header: "", key: "f", width: 50 },
              ];

              const cellB3 = sheet.getCell("B3");
              cellB3.value = "Inspection Checklist for FBO Dashboard system";
              cellB3.style = {
                font: { bold: true, name: "Calibri" },
                width: 100,
              };
              cellB3.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "deebf7" },
              };

              const cellC3 = sheet.getCell("C3");
              cellC3.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "deebf7" },
              };

              const cellB7 = sheet.getCell("B7");
              cellB7.value = "Date";
              cellB7.style = {
                font: { bold: true, name: "Calibri" },
              };
              cellB7.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "deebf7" },
              };

              const cellC7 = sheet.getCell("C7");
              cellC7.value = datto;
              cellC7.style = {
                font: { bold: true, name: "Calibri" },
              };

              const cellB8 = sheet.getCell("B8");
              cellB8.value = "Name of auditor";
              cellB8.style = {
                font: { bold: true, name: "Calibri" },
              };
              cellB8.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "deebf7" },
              };

              const cellC8 = sheet.getCell("C8");
              cellC8.value = name;
              cellC8.style = {
                font: { bold: true, name: "Calibri" },
              };

              const cellB9 = sheet.getCell("B9");
              cellB9.value = "FBO License No.";
              cellB9.style = {
                font: { bold: true, name: "Calibri" },
              };
              cellB9.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "deebf7" },
              };

              const cellC9 = sheet.getCell("C9");
              cellC9.value = currentData["license"];
              cellC9.style = {
                font: { bold: true, name: "Calibri" },
              };

              const cellB10 = sheet.getCell("B10");
              cellB10.value = "FBO Name";
              cellB10.style = {
                font: { bold: true, name: "Calibri" },
              };
              cellB10.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "deebf7" },
              };

              const cellC10 = sheet.getCell("C10");
              cellC10.value = messes[messIndex]["name"];
              cellC10.style = {
                font: { bold: true, name: "Calibri" },
              };

              const cellB11 = sheet.getCell("B11");
              cellB11.value = "FBO's representative";
              cellB11.style = {
                font: { bold: true, name: "Calibri" },
              };
              cellB11.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "deebf7" },
              };

              const cellC11 = sheet.getCell("C11");
              cellC11.value = currentData["representative"];
              cellC11.style = {
                font: { bold: true, name: "Calibri" },
              };

              const cellB12 = sheet.getCell("B12");
              cellB12.value = "Address";
              cellB12.style = {
                font: { bold: true, name: "Calibri" },
              };
              cellB12.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "deebf7" },
              };

              const cellC12 = sheet.getCell("C11");
              cellC12.value = currentData["address"];
              cellC12.style = {
                font: { bold: true, name: "Calibri" },
              };

              for (let i = 7; i <= 12; i++) {
                sheet.getCell(`B${i}`).border = {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" },
                };
                sheet.getCell(`C${i}`).border = {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" },
                };
              }

              const cellB16 = sheet.getCell("B16");
              cellB16.value = "S. No.";
              cellB16.style = {
                font: { bold: true, name: "Calibri" },
              };
              cellB16.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
              cellB16.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "deebf7" },
              };

              const cellC16 = sheet.getCell("C16");
              cellC16.value = "FBOs Inspection Question";
              cellC16.style = {
                font: { bold: true, name: "Calibri" },
              };
              cellC16.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
              cellC16.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "deebf7" },
              };

              const cellD16 = sheet.getCell("D16");
              cellD16.value = "Max Score";
              cellD16.style = {
                font: { bold: true, name: "Calibri" },
              };
              cellD16.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
              cellD16.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "deebf7" },
              };

              const cellE16 = sheet.getCell("E16");
              cellE16.value = "Obtained score";
              cellE16.style = {
                font: { bold: true, name: "Calibri" },
              };
              cellE16.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
              cellE16.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "deebf7" },
              };

              criteriaList.map((category, index) => {
                const cellB = sheet.getCell("B" + (16 + index + 1).toString());
                cellB.value = (index + 1).toString();
                cellB.style = {
                  font: { bold: true, name: "Calibri" },
                };

                cellB.border = {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" },
                };

                const cellC = sheet.getCell("C" + (16 + index + 1).toString());
                cellC.value = category;
                cellC.style = {
                  font: { bold: true, name: "Calibri" },
                };

                cellC.border = {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" },
                };

                const cellD = sheet.getCell("D" + (16 + index + 1).toString());
                cellD.value = getTotalMaxScore(category);
                cellD.style = {
                  font: { bold: true, name: "Calibri" },
                };
                cellD.border = {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" },
                };

                const cellE = sheet.getCell("E" + (16 + index + 1).toString());
                cellE.value = getTotalScore(category);
                cellE.style = {
                  font: { bold: true, name: "Calibri" },
                };
                cellE.border = {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" },
                };
              });

              const cellB24 = sheet.getCell("B24");
              cellB24.value = "";
              cellB24.style = {
                font: { bold: true, name: "Calibri" },
              };

              const cellC24 = sheet.getCell("C24");
              cellC24.value = "Final Score";
              cellC24.style = {
                font: { bold: true, name: "Calibri" },
              };
              cellC24.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };

              const cellD24 = sheet.getCell("D24");
              cellD24.value = getTotalMaxScoreAll();
              cellD24.style = {
                font: { bold: true, name: "Calibri" },
              };
              cellD24.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };

              const cellE24 = sheet.getCell("E24");
              cellE24.value = getTotal();
              cellE24.style = {
                font: { bold: true, name: "Calibri" },
              };
              cellE24.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };

              const cellB25 = sheet.getCell("B25");
              cellB25.value = "";
              cellB25.style = {
                font: { bold: true, name: "Calibri" },
              };

              const cellC25 = sheet.getCell("C25");
              cellC25.value = "Final Percentage";
              cellC25.style = {
                font: { bold: true, name: "Calibri" },
              };
              cellC25.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };

              const cellD25 = sheet.getCell("D25");
              cellD25.value = "";
              cellD25.style = {
                font: { bold: true, name: "Calibri" },
              };
              cellD25.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };

              const cellE25 = sheet.getCell("E25");
              cellD25.value = (getTotal() / getTotalMaxScoreAll()) * 100;
              cellE25.style = {
                font: { bold: true, name: "Calibri" },
              };
              cellE25.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };

              const cellB27 = sheet.getCell("B27");
              cellB27.value = "Grading";
              cellB27.style = {
                font: { bold: true, name: "Calibri" },
              };
              cellB27.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
              cellB27.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "deebf7" },
              };

              const cellC27 = sheet.getCell("C27");
              cellC27.value = "Grade";
              cellC27.style = {
                font: { bold: true, name: "Calibri" },
              };
              cellC27.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
              cellC27.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "deebf7" },
              };

              const cellD27 = sheet.getCell("D27");
              cellD27.value = "Marks";
              cellD27.style = {
                font: { bold: true, name: "Calibri" },
              };
              cellD27.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
              cellD27.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "deebf7" },
              };

              const cellB28 = sheet.getCell("B28");
              cellB28.value = "Compliance-Exemplary";
              cellB28.style = {
                font: { name: "Calibri" },
              };
              cellB28.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };

              const cellC28 = sheet.getCell("C28");
              cellC28.value = "A+";
              cellC28.style = {
                font: { name: "Calibri" },
              };
              cellC28.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };

              const cellD28 = sheet.getCell("D28");
              cellD28.value = "100-114";
              cellD28.style = {
                font: { name: "Calibri" },
              };
              cellD28.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };

              // Row 29
              const cellB29 = sheet.getCell("B29");
              cellB29.value = "Compliance/Satisfactory";
              cellB29.style = {
                font: { name: "Calibri" },
              };
              cellB29.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };

              const cellC29 = sheet.getCell("C29");
              cellC29.value = "A";
              cellC29.style = {
                font: { name: "Calibri" },
              };
              cellC29.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };

              const cellD29 = sheet.getCell("D29");
              cellD29.value = "91-99";
              cellD29.style = {
                font: { name: "Calibri" },
              };
              cellD29.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };

              // Row 30
              const cellB30 = sheet.getCell("B30");
              cellB30.value = "Needs Improvement";
              cellB30.style = {
                font: { name: "Calibri" },
              };
              cellB30.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };

              const cellC30 = sheet.getCell("C30");
              cellC30.value = "B";
              cellC30.style = {
                font: { name: "Calibri" },
              };
              cellC30.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };

              const cellD30 = sheet.getCell("D30");
              cellD30.value = "77-90";
              cellD30.style = {
                font: { name: "Calibri" },
              };
              cellD30.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };

              // Row 31
              const cellB31 = sheet.getCell("B31");
              cellB31.value = "Non Compliance";
              cellB31.style = {
                font: { name: "Calibri" },
              };
              cellB31.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };

              const cellC31 = sheet.getCell("C31");
              cellC31.value = "No Grade";
              cellC31.style = {
                font: { name: "Calibri" },
              };
              cellC31.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };

              const cellD31 = sheet.getCell("D31");
              cellD31.value = "<77";
              cellD31.style = {
                font: { name: "Calibri" },
              };
              cellD31.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };

              const cellB35 = sheet.getCell("B35");
              cellB35.value = "Sr.no";
              cellB35.style = {
                font: { bold: true, name: "Calibri" },
              };
              cellB35.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
              cellB35.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "deebf7" },
              };

              const cellC35 = sheet.getCell("C35");
              cellC35.value = "Audit Scoring";
              cellC35.style = {
                font: { bold: true, name: "Calibri" },
              };
              cellC35.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
              cellC35.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "deebf7" },
              };

              const cellD35 = sheet.getCell("D35");
              cellD35.value = "Max Score";
              cellD35.style = {
                font: { bold: true, name: "Calibri" },
              };

              cellD35.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
              cellD35.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "deebf7" },
              };

              const cellE35 = sheet.getCell("E35");
              cellE35.value = "Obtained Score";
              cellE35.style = {
                font: { bold: true, name: "Calibri" },
              };
              cellE35.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
              cellE35.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "deebf7" },
              };

              const cellF35 = sheet.getCell("F35");
              cellF35.value = "Remarks";
              cellF35.style = {
                font: { bold: true, name: "Calibri" },
              };
              cellF35.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
              cellF35.fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "deebf7" },
              };

              Object.keys(criteria).map((cri, index) => {
                sheet.getRow(36 + index + 1).height = 70;

                const cellB = sheet.getCell("B" + (36 + index + 1).toString());
                cellB.value = cri;
                cellB.style = {
                  font: { name: "Calibri" },
                };

                cellB.alignment = {
                  wrapText: true,
                  horizontal: "center",
                  vertical: "middle",
                };
                cellB.border = {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" },
                };
                const cellC = sheet.getCell("C" + (36 + index + 1).toString());
                cellC.value = criteria[cri]["criteria"];
                cellC.style = {
                  font: { name: "Calibri" },
                };
                cellC.alignment = {
                  wrapText: true,
                  horizontal: "center",
                  vertical: "middle",
                };
                cellC.border = {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" },
                };
                const cellD = sheet.getCell("D" + (36 + index + 1).toString());
                cellD.value = criteria[cri]["max_score"];
                cellD.style = {
                  font: { name: "Calibri" },
                };
                cellD.alignment = {
                  wrapText: true,
                  horizontal: "center",
                  vertical: "middle",
                };
                cellD.border = {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" },
                };
                const cellE = sheet.getCell("E" + (36 + index + 1).toString());
                cellE.value = currentData[cri];
                cellE.style = {
                  font: { name: "Calibri" },
                };
                cellE.alignment = {
                  wrapText: true,
                  horizontal: "center",
                  vertical: "middle",
                };
                cellE.border = {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" },
                };
                const cellF = sheet.getCell("F" + (36 + index + 1).toString());
                cellF.value = currentData[cri + "remarks"];
                cellF.style = {
                  font: { name: "Calibri" },
                };
                cellF.alignment = {
                  wrapText: true,
                  horizontal: "center",
                  vertical: "middle",
                };
                cellF.border = {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" },
                };
              });

              workbook.xlsx.writeBuffer().then(function (data) {
                const blob = new Blob([data], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement("a");
                anchor.href = url;
                anchor.download = "download.xlsx";
                anchor.click();
                window.URL.revokeObjectURL(url);
              });
            }}
            fullWidth
          >
            <DownloadIcon />
            <h3 style={{ marginTop: "18px" }}>Download XL</h3>
          </Button>
          <br />
          <br />
          <Grid
            container
            spacing={2}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            {currentData["files"].map((firu, index) => {
              return (
                <Grid item md={4}>
                  {firu}
                  <img
                    src={"http://auditsafeapi.impulsion.in/media/" + firu}
                    style={{
                      width: "100%",
                      margin: "10px",
                      border: "1px solid grey",
                      borderRadius: "5px",
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
          {state.auth.isMobile ? <div style={{ height: "300px" }} /> : ""}
        </Grid>
      </Grid>
    );
  }

  if (!state.auth.isLoading) {
    if (
      state.auth.isAuthenticated &&
      (state.auth.user.is_staff || state.auth.user.is_manager)
    ) {
      if (isLoading) {
        return (
          <Fragment>
            <Sidebar />

            <div
              style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: themeStyle.backgroundColor,
              }}
            >
              <CircularProgress style={{ color: themeStyle.primaryColor }} />
            </div>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <Sidebar />

            <div
              style={{
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.backgroundColor
                    : themeStyle.backgroundColor,
                height: "100vh",
                width: "100vw",
                padding: "20px",
              }}
            >
              {getContent()}
            </div>
          </Fragment>
        );
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <Fragment>
        <Sidebar />
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: themeStyle.backgroundColor,
          }}
        >
          <CircularProgress style={{ color: themeStyle.primaryColor }} />
        </div>
      </Fragment>
    );
  }
}

export default ViewEntry;

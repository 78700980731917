import React, { useEffect, Fragment, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Router, Route, Routes, useNavigate } from "react-router-dom";
import Login from "./pages/Login";
import Forgot from "./pages/Forgot";
import Dashboard from "./pages/Dashboard";
import Loader from "./pages/Loader";
import Logout from "./pages/Logout";
import Oops from "./pages/Oops";
import ErrorReport from "./pages/ErrorReport";
import Profiles from "./pages/Profiles";
import NewEntry from "./pages/NewEntry";

import { loadUser, TEST } from "./actions/auth";
import ViewEntry from "./pages/ViewEntry";

process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";

function App() {
  const dispatch = useDispatch();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch({ type: "USER_LOADING" });
    dispatch(loadUser());
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  function handleWindowSizeChange() {
    if (window.innerWidth <= 1300) {
      dispatch({ type: "MOBILE_VIEW", payload: true });
    } else {
      dispatch({ type: "MOBILE_VIEW", payload: false });
    }
  }

  if (state.auth.loginFailed) {
    navigate("/login", { replace: true });
    dispatch({ type: "RESET_REDIRECT_LOGIN" });
  }

  return (
    <Routes>
      <Route exact path="/" element={<Loader />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/login" element={<Login />} />
      <Route path="/forgotpassword" element={<Forgot />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/error" element={<ErrorReport />} />
      <Route path="/new_entry" element={<NewEntry />} />
      <Route path="/profiles" element={<Profiles />} />
      <Route path="/view_data" element={<ViewEntry />} />
      <Route path="*" element={<Oops />} />
    </Routes>
  );
}

export default App;

import React, { useEffect, useState, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import moment from "moment";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { alertMessage } from "../actions/auth";
import { Bar } from "react-chartjs-2";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Chart } from "chart.js/auto";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Fab } from "@mui/material";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Checkbox from "@mui/material/Checkbox";
import DownloadIcon from "@mui/icons-material/Download";
import QrCodeIcon from "@mui/icons-material/QrCode";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import CloseIcon from "@mui/icons-material/Close";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Criteria from "../components/Criteria";
import imageCompression from "browser-image-compression";

import { API_URL } from "../actions/auth";

import { themeStyle, themeStyleDark } from "../components/Components";

function NewEntry() {
  const [nameOfAuditor, setNameOfAuditor] = useState("");
  const [fboName, setFboName] = useState("");
  const [fboRepresentative, setFboRepresentative] = useState("");
  const [licenseNo, setLicenseNo] = useState("");

  const [pointsObserved, setPointsObserved] = useState([]);
  const [improvements, setImprovements] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);

  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);

  const [messes, setMesses] = useState("");
  const [messIndex, setMessIndex] = useState(0);
  const [criteriaList, setCriteriaList] = useState([]);
  const [criteria, setCriteria] = useState([]);
  const [submission, setSubmitData] = useState({});
  const [random, setRandom] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFilesPaths, setSelectedFilesPaths] = useState([]);
  const [fileNames, setFileNames] = useState([]);

  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function setSubmissionData(key, value) {
    var tempData = submission;
    tempData[key] = value;
    setSubmitData(tempData);
    setRandom(makeid(5));
  }

  function getTotalScore(category) {
    var criKeys = Object.keys(criteria);
    var total = 0;
    for (var i = 0; i < criKeys.length; i++) {
      if (criteria[criKeys[i]]["category"] == category) {
        if (submission[criKeys[i]].length != 0) {
          total += parseInt(submission[criKeys[i]]);
        }
      }
    }
    return total;
  }

  function getTotal() {
    var criKeys = Object.keys(criteria);
    var total = 0;
    for (var i = 0; i < criKeys.length; i++) {
      if (submission[criKeys[i]].length != 0) {
        total += parseInt(submission[criKeys[i]]);
      }
    }
    return total;
  }

  function getData() {
    var body = {
      email: state.auth.user.email,
    };
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };
    axios
      .post(API_URL + "get_criteria", JSON.stringify(body), config)
      .then((res) => {
        setCriteria(res.data["criteria"]);
        setCriteriaList(res.data["criteria_list"]);

        var tempData = {};
        var criKeys = Object.keys(res.data["criteria"]);

        for (var i = 0; i < criKeys.length; i++) {
          tempData[criKeys[i]] = {};
          tempData[criKeys[i]] = "1";
          tempData[criKeys[i] + "remarks"] = "";
        }
        setSubmitData(tempData);
        axios.post(API_URL + "get_messes", body, config).then((res) => {
          setMesses(res.data);
          setIsLoading(false);
        });
      });
  }

  function submitData() {
    submission["date"] = moment().format("DD-MM-YYYY");
    submission["email"] = state.auth.user.email;
    submission["files"] = fileNames;

    var body = {
      email: state.auth.user.email,
      mess_id: messes[messIndex]["mess_id"],
      data: submission,
    };

    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };

    axios.post(API_URL + "add_entry", body, config).then((res) => {
      setIsSubmitting(false);
      setIsSuccessful(true);
      onFileUpload();
    });
  }

  if (state.auth.refresh == "REFRESH_DATA") {
    if (state.auth.user.is_staff || state.auth.user.is_manager) {
      getData();
    }
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  function onFileUpload() {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    for (var i = 0; i < selectedFiles.length; i++) {
      formData.append("files", selectedFiles[i], fileNames[i]);
    }
    // Details of the Auditsafeed file
    console.log(selectedFiles);

    // Request made to the backend api
    // Send formData object

    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        // "Content-Type": "application/json",
      },
    };

    formData.append("email", state.auth.user.email);

    console.log(typeof formData);
    axios
      .post(API_URL + "add_images", formData, config)
      .then(() => {
        createAlert("SUCCESS", "Success.", "Document saved.");
        setTimeout(() => {
          setOpen(false);
          setIsSuccessful(false);
          navigate("/");
        }, 2000);
      })
      .catch((err) => {
        console.log("___");
        console.log(err);

        createAlert("ERROR", "Error", "An error occured.");
      });

    document.getElementById("contained-button-file").value = "";
  }

  async function onFileChange(event) {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    var tempPaths = [];
    var tempNames = [];
    var tempFiles = [];
    for (var i = 0; i < event.target.files.length; i++) {
      var compressedFile;
      try {
        compressedFile = await imageCompression(event.target.files[i], options);
      } catch (error) {
        console.log(error);
      }

      tempFiles.push(compressedFile);
      tempPaths.push(URL.createObjectURL(event.target.files[i]));
      tempNames.push(makeid(10) + ".jpg");
    }
    setSelectedFiles(tempFiles);
    setFileNames(tempNames);
    setSelectedFilesPaths(tempPaths);
  }

  function onFileClick(event) {
    // Update the state
    document.getElementById("contained-button-file").value = "";
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      if (state.auth.user.is_staff || state.auth.user.is_manager) {
        getData();
      }
    }
  }, []);

  function getMessList() {
    var messess = Object.keys(messes).map((mess, index) => (
      <MenuItem value={index}>{messes[index]["name"]}</MenuItem>
    ));
    return messess;
  }

  function handleMessChange(e) {
    setMessIndex(e.target.value);
  }

  const CriteriaTitle = () => {
    return (
      <div
        style={{
          marginTop: "25px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ width: "60%", fontSize: "30px" }}></div>

        <div
          style={{
            width: "20%",
            fontSize: "22px",
            textAlign: "center",
          }}
        >
          Max Scr
        </div>
        <div
          style={{
            width: "20%",
            fontSize: "22px",
            textAlign: "center",
          }}
        >
          Obt. Scr
        </div>
      </div>
    );
  };
  function getContent() {
    return (
      <Grid
        container
        spacing={5}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
      >
        <Grid
          item
          md={8}
          xs={12}
          style={{ paddingRight: state.auth.isMobile ? 0 : "20px" }}
        >
          <span
            style={{
              color: themeStyle.primaryColor,
              fontSize: "30px",
            }}
          >
            Audit Questions
          </span>
          {criteriaList.map((category, index) => (
            <Accordion
              style={{
                marginTop: "30px",
                borderRadius: "15px",
              }}
            >
              <AccordionSummary
                style={{
                  padding: "10px 25px 10px 25px",
                  backgroundColor: themeStyle.primaryButtonColor,
                  borderRadius: "15px",
                  fontWeight: 600,
                  color: "white",
                  fontSize: "25px",
                }}
                expandIcon={
                  <ExpandMoreIcon
                    style={{ fontSize: "50px", color: "white" }}
                  />
                }
                aria-controls="panel1-content"
                id="panel1-header"
              >
                {category}
              </AccordionSummary>

              <AccordionDetails
                style={{
                  borderBottomLeftRadius: "15px",
                  borderBottomRightRadius: "15px",
                }}
              >
                {Object.entries(criteria).map(([key, value]) =>
                  value["category"] == category ? (
                    <Fragment>
                      <CriteriaTitle />

                      <Fragment>
                        <div
                          style={{
                            marginTop: "25px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "60%" }}>
                            <span
                              style={{
                                color: themeStyle.primaryColor,
                                fontWeight: "bold",
                                fontSize: "20px",
                              }}
                            >
                              Criteria {key}
                            </span>
                            <br />
                            <span style={{ fontSize: "22px" }}>
                              {value["criteria"]}
                            </span>
                          </div>
                          <div
                            style={{
                              width: "20%",
                              fontSize: "22px",
                              textAlign: "center",
                            }}
                          >
                            {value["max_score"]}
                          </div>
                          <TextField
                            style={{
                              backgroundColor: "white",
                              borderRadius: "6px",
                              width: "20%",
                            }}
                            type="number"
                            inputProps={{
                              style: { fontSize: "22px", textAlign: "center" },
                            }}
                            InputLabelProps={{
                              style: { fontSize: "22px", textAlign: "center" },
                            }}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            placeholder="Score Q1"
                            value={submission[key]}
                            onChange={(e) => {
                              if (
                                parseInt(e.target.value) > value["max_score"]
                              ) {
                                setSubmissionData(key, value["max_score"]);
                              } else if (parseInt(e.target.value) < 0) {
                                setSubmissionData(key, 0);
                              } else {
                                setSubmissionData(key, e.target.value);
                              }
                            }}
                          />
                        </div>
                        <TextField
                          style={{
                            backgroundColor: "white",
                            borderRadius: "6px",
                            textAlign: "center",
                          }}
                          inputProps={{
                            style: { fontSize: "22px", textAlign: "center" },
                          }} // font size of input text
                          InputLabelProps={{
                            style: { fontSize: "22px", textAlign: "center" },
                          }}
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          placeholder={"Remarks for Criteria " + key}
                          value={submission[key + "remarks"]}
                          onChange={(e) => {
                            setSubmissionData(key + "remarks", e.target.value);
                          }}
                        />
                      </Fragment>
                    </Fragment>
                  ) : (
                    <Fragment />
                  )
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
        <Grid
          item
          md={4}
          style={{
            borderLeft: "2px solid " + themeStyle.primaryColor,
            backgroundColor: "white",
            paddingRight: "50px",
            height: "92vh",
            width: "110vw",
          }}
        >
          <span style={{ color: themeStyle.primaryColor, fontWeight: "bold" }}>
            Select Entity
          </span>
          <Select
            style={{
              backgroundColor: "white",
              marginTop: "2px",
              height: "55px",
              marginBottom: "5px",
              fontSize: "22px",
            }}
            variant={"outlined"}
            fullWidth
            value={messIndex}
            onChange={(e) => handleMessChange(e)}
          >
            {getMessList()}
          </Select>
          <div
            style={{
              width: "100%",
              height: "2px",
              backgroundColor: "#ddd",
              marginTop: "25px",
            }}
          ></div>
          <div
            style={{
              fontSize: "40px",
              marginTop: "15px",
              color: "blue",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <span> Total Score</span>
            <span>{getTotal()}</span>
          </div>
          {criteriaList.map((category, index) => (
            <div
              style={{
                marginTop: "25px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: "22px" }}>{category}</span>
              <div style={{ fontSize: "20px", color: "blue" }}>
                {getTotalScore(category)}
              </div>
            </div>
          ))}
          <div style={{ height: "50px" }} />

          <label
            style={{ width: "100%", marginTop: "50px" }}
            htmlFor="contained-button-file"
          >
            <input
              style={{ display: "none" }}
              id="contained-button-file"
              type="file"
              multiple
              accept="image/*"
              onChange={(e) => onFileChange(e)}
            />

            <Button
              style={{
                width: "100%",
                margin: "25px 0px 0px 0px",
                height: "50px",
                marginTop: "-1px",
                backgroundColor: themeStyle.primaryColor,
                fontFamily: "Bahnschrift",
              }}
              variant="contained"
              component="span"
            >
              <UploadFileIcon />
              &nbsp;&nbsp; <h3 style={{ marginTop: "18px" }}>UPLOAD IMAGES</h3>
            </Button>
          </label>
          <Button
            style={{
              height: "46px",
              margin: "25px 0px 0px 0px",
              backgroundColor: themeStyle.primaryColor,
              boxShadow: "none",
            }}
            variant="contained"
            onClick={() => setOpen(true)}
            fullWidth
          >
            <CheckCircle />
            &nbsp;&nbsp; <h3 style={{ marginTop: "18px" }}>SUBMIT ENTRY</h3>
          </Button>
          <br />
          <br />
          <Grid
            container
            spacing={2}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            {selectedFilesPaths.map((firu, index) => {
              return (
                <Grid item md={4}>
                  <img
                    src={firu}
                    style={{
                      width: "100%",
                      margin: "10px",
                      border: "1px solid grey",
                      borderRadius: "5px",
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
          {state.auth.isMobile ? <div style={{ height: "300px" }} /> : ""}
        </Grid>
      </Grid>
    );
  }

  if (!state.auth.isLoading) {
    if (
      state.auth.isAuthenticated &&
      (state.auth.user.is_staff || state.auth.user.is_manager)
    ) {
      if (isLoading) {
        return (
          <Fragment>
            <Sidebar />

            <div
              style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: themeStyle.backgroundColor,
              }}
            >
              <CircularProgress style={{ color: themeStyle.primaryColor }} />
            </div>
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <Sidebar />
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={open}
              onClose={() => {
                setOpen(false);
                setIsSubmitting(false);
              }}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={open}>
                <div
                  style={{
                    position: "absolute",
                    top: "50%",
                    backgroundColor: "white",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 400,
                    padding: "10px",
                    height: "300px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {isSubmitting ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <CircularProgress />{" "}
                    </div>
                  ) : !isSuccessful ? (
                    <div style={{ marginTop: "-25px" }}>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton size="small">
                          <CloseIcon
                            onClick={() => setOpen(false)}
                            style={{ color: "#3182bd" }}
                          />
                        </IconButton>
                      </div>
                      <h3>Submit entry?</h3>

                      <br></br>
                      <span>Database records will be updated.</span>
                      <div>
                        This action is irreversible, the data you enter cannot
                        be changed hereafter.
                      </div>
                      <br></br>
                      <br></br>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          style={{ height: "30px" }}
                          variant="contained"
                          onClick={() => setOpen(false)}
                        >
                          No
                        </Button>
                        <Button
                          onClick={() => {
                            setIsSubmitting(true);
                            submitData();
                          }}
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                        >
                          Yes
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <CheckCircle
                      style={{ fontSize: "75px", color: "#5cb85c" }}
                    />
                  )}
                </div>
              </Fade>
            </Modal>
            <div
              style={{
                backgroundColor:
                  state.auth.theme == "dark"
                    ? themeStyleDark.backgroundColor
                    : themeStyle.backgroundColor,
                height: "100vh",
                width: "100vw",
                padding: "20px",
              }}
            >
              {getContent()}
            </div>
          </Fragment>
        );
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <Fragment>
        <Sidebar />
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: themeStyle.backgroundColor,
          }}
        >
          <CircularProgress style={{ color: themeStyle.primaryColor }} />
        </div>
      </Fragment>
    );
  }
}

export default NewEntry;

import React, { useEffect, useState, Fragment, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  BarElement,
  RadialLinearScale,
  ArcElement,
  Legend,
  scales,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import { PolarArea } from "react-chartjs-2";
import zoomPlugin from "chartjs-plugin-zoom";

import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import DeleteIcon from "@mui/icons-material/Delete";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Fab } from "@mui/material";
import Fade from "@mui/material/Fade";
import AddIcon from "@mui/icons-material/Add";
import PublishIcon from "@mui/icons-material/Publish";
import Drawer from "@mui/material/Drawer";
import Checkbox from "@mui/material/Checkbox";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import debounce from "lodash/debounce";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { themeStyle } from "../components/Components";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  BarElement,
  Filler,
  Legend,
  RadialLinearScale,
  ArcElement,
  zoomPlugin
);

function Dashboard() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [selectedCategory, setselectedCategory] = useState(
    "Regulatory Compliance"
  );
  const [selectedDate, setSelectedDate] = useState("20/04/2024");
  const [selectedGroup, setSelectedGroup] = useState("INDIVIDUAL");
  const [showLables, setShowLables] = useState(true);
  const [dateList, setDateList] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState("Meadows");
  const [messes, setMesses] = useState("");
  const [selectedMess, setSelectedMess] = useState(0);
  const [currentKey, setCurrentKey] = useState("a1");
  const [criteria, setCriteria] = useState([]);

  const [messData, setMessData] = useState([]);
  const [criteriaList, setCriteriaList] = useState([]);

  const [data, setData] = useState([]);

  function getData() {
    var body = { email: state.auth.user.email };
    const config = {
      headers: {
        Authorization: state.auth.token,
        "Content-Type": "application/json",
      },
    };
    axios
      .post(API_URL + "get_criteria", JSON.stringify(body), config)
      .then((res) => {
        setCriteria(res.data["criteria"]);
        setCriteriaList(res.data["criteria_list"]);
        axios
          .post(API_URL + "get_messes", JSON.stringify(body), config)
          .then((res) => {
            setMesses(res.data);
            body["mess_id"] = res.data[0]["mess_id"];
            axios
              .post(API_URL + "get_data", JSON.stringify(body), config)
              .then((res) => {
                console.log(res.data);
                setMessData(res.data);
                setIsLoading(false);
                setIsLoadingData(false);
              });
          });
      });
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      if (state.auth.user.is_staff || state.auth.user.is_manager) {
        getData();
      }
    }
  }, []);

  function handleTabChange(tab) {
    if (tab == "Regulatory Compliance") {
      setCurrentKey("a1");
    } else if (tab == "Design & facilities") {
      setCurrentKey("b2");
    } else if (tab == "Control of operation") {
      setCurrentKey("c16");
    } else if (tab == "Maintenance & sanitation") {
      setCurrentKey("d33");
    } else if (tab == "Personal Hygiene") {
      setCurrentKey("e40");
    } else if (tab == "Training & records keeping") {
      setCurrentKey("f44");
    }
    setselectedCategory(tab);
  }

  function getDates() {
    var dats = dateList.map((dat, index) => (
      <MenuItem value={dat}>{moment(dat).format("DD-MM-YYYY")}</MenuItem>
    ));

    return dats;
  }

  const chartRef = React.useRef(null);

  const handleResetZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  function getOverallContent() {
    var options = {
      responsive: true,
      plugins: {
        zoom: {
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: "xy",
          },
        },
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: selectedCategory,
        },
      },
    };

    function getData() {
      var datList = [];
      var values = [];
      var remarks = [];

      for (var i = 0; i < messData.length; i++) {
        datList.push(messData[i]["date"]);
        var total = 0;
        var dataKeys = Object.keys(messData[i]);
        for (var j = 0; j < dataKeys.length; j++) {
          if (
            dataKeys[j] != "date" &&
            dataKeys[j] != "email" &&
            !dataKeys[j].includes("remarks")
          ) {
            total += parseInt(messData[i][dataKeys[j]]);
          }
        }
        values.push(total);
        remarks.push(messData[i][currentKey + "remarks"]);
      }
      return {
        dates: datList,
        values: values,
        remarks: remarks.filter((v) => v != ""),
      };
    }

    const graap = getData();
    console.log(graap);

    const dataGraph = {
      labels: graap["dates"],
      datasets: [
        {
          fill: true,
          label: "",
          data: graap["values"],
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
          datalabels: {
            display: showLables,
            align: "end",
            anchor: "end",
            color: "rgb(53, 162, 235)",
          },
        },
      ],
    };

    var selectList = [];
    var criKeys = Object.keys(criteria);

    for (var i = 0; i < criKeys.length; i++) {
      if (criteria[criKeys[i]]["category"] == selectedCategory) {
        selectList.push(criKeys[i]);
      }
    }

    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "75%" }}>
          <Bar
            plugins={[ChartDataLabels, zoomPlugin]}
            ref={chartRef}
            options={options}
            data={dataGraph}
          />
        </div>
      </div>
    );
  }

  function getTotalContent() {
    var options = {
      responsive: true,
      plugins: {
        zoom: {
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: "xy",
          },
        },
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: selectedCategory,
        },
      },
    };

    function getData() {
      var datList = [];
      var values = [];
      var remarks = [];

      for (var i = 0; i < messData.length; i++) {
        datList.push(messData[i]["date"]);
        var total = 0;
        var dataKeys = Object.keys(messData[i]);
        for (var j = 0; j < dataKeys.length; j++) {
          if (
            dataKeys[j] != "date" &&
            dataKeys[j] != "email" &&
            !dataKeys[j].includes("remarks")
          ) {
            if (criteria[dataKeys[j]]["category"] == selectedCategory) {
              total += parseInt(messData[i][dataKeys[j]]);
            }
          }
        }
        values.push(total);
        remarks.push(messData[i][currentKey + "remarks"]);
      }
      return {
        dates: datList,
        values: values,
        remarks: remarks.filter((v) => v != ""),
      };
    }

    const graap = getData();
    const dataGraph = {
      labels: graap["dates"],
      datasets: [
        {
          fill: true,
          label: "",
          data: graap["values"],
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
          datalabels: {
            display: showLables,
            align: "end",
            anchor: "end",
            color: "rgb(53, 162, 235)",
          },
        },
      ],
    };

    var selectList = [];
    var criKeys = Object.keys(criteria);

    for (var i = 0; i < criKeys.length; i++) {
      if (criteria[criKeys[i]]["category"] == selectedCategory) {
        selectList.push(criKeys[i]);
      }
    }

    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ width: "65%" }}>
          <Bar
            plugins={[ChartDataLabels, zoomPlugin]}
            ref={chartRef}
            options={options}
            data={dataGraph}
          />
        </div>
      </div>
    );
  }

  function getIndividualContent() {
    var options = {
      responsive: true,
      plugins: {
        zoom: {
          zoom: {
            wheel: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: "xy",
          },
        },
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: selectedCategory,
        },
      },
    };

    function getData() {
      var datList = [];
      var values = [];
      var remarks = [];

      for (var i = 0; i < messData.length; i++) {
        datList.push(messData[i]["date"]);
        values.push(messData[i][currentKey]);

        remarks.push(messData[i][currentKey + "remarks"]);
      }

      return {
        dates: datList,
        values: values,
        remarks: remarks.filter((v) => v != ""),
      };
    }

    const graap = getData();

    const dataGraph = {
      labels: graap["dates"],
      datasets: [
        {
          fill: true,
          label: "",
          data: graap["values"],
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
          datalabels: {
            display: showLables,
            align: "end",
            anchor: "end",
            color: "rgb(53, 162, 235)",
          },
        },
      ],
    };

    var selectList = [];
    var criKeys = Object.keys(criteria);

    for (var i = 0; i < criKeys.length; i++) {
      if (criteria[criKeys[i]]["category"] == selectedCategory) {
        selectList.push(criKeys[i]);
      }
    }

    return (
      <Grid
        container
        spacing={state.auth.isMobile ? 0 : 3}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
        style={{ marginLeft: "-10px" }}
      >
        <Grid item md={8} xs={12} style={{ marginLeft: "20px" }}>
          <Bar
            plugins={[ChartDataLabels, zoomPlugin]}
            ref={chartRef}
            options={options}
            data={dataGraph}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <div
            style={{
              margin: "20px",
              width: "100%",
              marginLeft: state.auth.isMobile ? "20px" : "-40px",
            }}
          >
            <Select
              value={currentKey}
              onChange={(e) => setCurrentKey(e.target.value)}
              style={{
                marginTop: "5px",
                height: "40px",
                marginBottom: "5px",
                backgroundColor: "white",
              }}
              fullWidth
              variant={"outlined"}
            >
              {selectList.map((sel, ind) => (
                <MenuItem value={sel}>{sel}</MenuItem>
              ))}
            </Select>
            <Accordion
              expanded={true}
              style={{
                marginTop: "15px",
                borderRadius: "15px",
              }}
            >
              <AccordionSummary
                style={{
                  padding: "",
                  backgroundColor: themeStyle.primaryButtonColor,
                  borderRadius: "15px",
                  fontWeight: 600,
                  color: "white",
                  fontSize: "16px",
                }}
                expandIcon={
                  <ExpandMoreIcon
                    style={{ fontSize: "30px", color: "white" }}
                  />
                }
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Criteria {currentKey}
              </AccordionSummary>

              <AccordionDetails
                style={{
                  borderBottomLeftRadius: "15px",
                  borderBottomRightRadius: "15px",
                }}
              >
                {criteria[currentKey]["criteria"]}
              </AccordionDetails>
            </Accordion>
            <div
              style={{ marginTop: "15px", fontSize: 30, textAlign: "center" }}
            >
              Maximum score is{" "}
              <span style={{ color: "blue" }}>
                {criteria[currentKey]["max_score"]}
              </span>
            </div>
            <ul style={{ color: "black" }}>
              {graap["remarks"].map((remark, index) => (
                <li style={{ fontSize: "20px" }}>
                  {remark + " on " + graap["dates"][index]}{" "}
                </li>
              ))}
            </ul>
          </div>
        </Grid>
      </Grid>
    );
  }

  function getMessList() {
    var messess = Object.keys(messes).map((mess, index) => (
      <MenuItem value={index}>{messes[index]["name"]}</MenuItem>
    ));
    return messess;
  }

  if (isLoading) {
    return (
      <Fragment>
        {state.auth.isAuthenticated == true ? <Sidebar /> : ""}
        <div
          style={{
            maxWidth: "88vw",
            marginTop: "45vh",
            marginLeft: "8vw",
            backgroundColor: "white",
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      </Fragment>
    );
  } else {
    return (
      <div
        style={{
          maxWidth: "100vw",
          marginRight: "20px",
          marginTop: state.auth.isMobile ? 0 : "10px",
          marginLeft: state.auth.isMobile ? 0 : "10px",
        }}
      >
        {state.auth.isAuthenticated == true ? <Sidebar /> : ""}
        <Grid
          container
          spacing={state.auth.isMobile ? 1 : 5}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          style={{ maxWidth: "100vw" }}
        >
          <Grid item md={12} xs={12}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                margin: state.auth.isMobile ? "20px" : 0,
                fontSize: state.auth.isMobile ? "20px" : "30px",
              }}
            >
              <span>{messes[selectedMess]["name"]}</span>

              <div style={{ display: "flex" }}>
                <Button
                  style={{ backgroundColor: "#184e77", color: "white" }}
                  onClick={() => setShowLables(!showLables)}
                >
                  {showLables ? "Hide" : "Show"} Labels
                </Button>
                <Button
                  style={{
                    backgroundColor: "#184e77",
                    color: "white",
                    marginLeft: "5px",
                    marginRight: "10px",
                  }}
                  onClick={handleResetZoom}
                >
                  Reset Zoom
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item md={9} xs={12} style={{ marginRight: "-50px" }}>
            <div
              style={{
                display: "flex",
                paddingLeft: "20px",
                width: state.auth.isMobile ? "105%" : "100%",
                marginTop: state.auth.isMobile ? "-30px" : 0,
              }}
            >
              {selectedGroup == "INDIVIDUAL" ? (
                <Button
                  fullWidth
                  value={"INDIVIDUAL"}
                  onClick={() => setSelectedGroup("INDIVIDUAL")}
                  p={2}
                  style={{
                    backgroundColor: "#184e77",
                    color: "white",
                    height: "80px",
                    marginTop: "15px",
                  }}
                  borderRadius={6}
                >
                  <h3
                    style={{
                      marginTop: "21px",
                      fontSize: "20px",
                    }}
                  >
                    INDIVIDUAL
                  </h3>
                </Button>
              ) : (
                <Button
                  fullWidth
                  value={"INDIVIDUAL"}
                  onClick={() => setSelectedGroup("INDIVIDUAL")}
                  p={2}
                  style={{
                    backgroundColor: "#d7ecff",
                    color: "white",
                    height: "80px",
                    marginTop: "15px",
                  }}
                  borderRadius={6}
                >
                  <h3
                    style={{
                      marginTop: "21px",
                      fontSize: "20px",
                      color: "#084594",
                    }}
                  >
                    INDIVIDUAL
                  </h3>
                </Button>
              )}
              {selectedGroup == "TOTAL" ? (
                <Button
                  fullWidth
                  value={"TOTAL"}
                  onClick={() => {
                    setSelectedGroup("TOTAL");
                  }}
                  p={2}
                  style={{
                    backgroundColor: "#184e77",
                    color: "white",
                    height: "80px",
                    margin: "10px",
                    marginTop: "15px",
                    marginLeft: "10px",
                  }}
                  borderRadius={6}
                >
                  <h3
                    style={{
                      marginTop: "21px",
                      fontSize: "20px",
                    }}
                  >
                    TOTAL
                  </h3>
                </Button>
              ) : (
                <Button
                  fullWidth
                  value={"TOTAL"}
                  onClick={() => {
                    setSelectedGroup("TOTAL");
                  }}
                  p={2}
                  style={{
                    backgroundColor: "#d7ecff",
                    color: "white",
                    height: "80px",
                    margin: "10px",
                    marginTop: "15px",
                    marginLeft: "10px",
                  }}
                  borderRadius={6}
                >
                  <h3
                    style={{
                      marginTop: "21px",
                      fontSize: "20px",
                      color: "#084594",
                    }}
                  >
                    TOTAL
                  </h3>
                </Button>
              )}
              {selectedGroup == "OVERALL" ? (
                <Button
                  fullWidth
                  value={"OVERALL"}
                  onClick={() => setSelectedGroup("OVERALL")}
                  p={2}
                  style={{
                    backgroundColor: "#184e77",
                    color: "white",
                    height: "80px",
                    marginTop: "15px",
                    marginRight: "10px",
                  }}
                  borderRadius={6}
                >
                  <h3
                    style={{
                      marginTop: "21px",
                      fontSize: "20px",
                    }}
                  >
                    OVERALL
                  </h3>
                </Button>
              ) : (
                <Button
                  fullWidth
                  value={"OVERALL"}
                  onClick={() => setSelectedGroup("OVERALL")}
                  p={2}
                  style={{
                    backgroundColor: "#d7ecff",
                    color: "white",
                    height: "80px",
                    marginTop: "15px",
                    marginRight: "10px",
                  }}
                  borderRadius={6}
                >
                  <h3
                    style={{
                      marginTop: "21px",
                      fontSize: "20px",
                      color: "#084594",
                    }}
                  >
                    OVERALL
                  </h3>
                </Button>
              )}
            </div>
          </Grid>

          <Grid item md={3} xs={12}>
            <div
              style={{
                boxShadow:
                  " 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2)",
                height: "120px",
                backgroundColor: "#184e77",
                width: "100%",
                margin: "15px 15px 0px 10px",
                padding: "20px",
                borderRadius: "25px",
                color: "white",
                fontSize: "20px",
              }}
            >
              <div
                style={{
                  color: "white",
                  fontFamily: "Lato-Regular",
                  fontSize: "14px",
                  marginTop: "15px",
                  backgroundColor: "#184e77",
                  padding: "3px",
                }}
              >
                Select Mess
              </div>
              <Select
                value={selectedMess}
                onChange={(e) => setSelectedMess(e.target.value)}
                style={{
                  marginTop: "2px",
                  height: "40px",
                  marginBottom: "5px",
                  backgroundColor: "white",
                  // color:"white"
                }}
                fullWidth
                variant={"outlined"}
              >
                {getMessList()}
              </Select>
            </div>
          </Grid>
          <br />
          {selectedGroup != "OVERALL" ? (
            <Grid
              container
              spacing={state.auth.isMobile ? 0 : 2}
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              style={{ marginLeft: state.auth.isMobile ? "0px" : "30px" }}
            >
              {criteriaList.map((cri, ind) => (
                <Grid item md={12 / criteriaList.length} xs={12}>
                  {selectedCategory == cri ? (
                    <Button
                      fullWidth
                      value={cri}
                      onClick={() => {}}
                      p={2}
                      style={{
                        backgroundColor: "#184e77",
                        color: "white",
                        height: "40px",
                        margin: "10px",
                      }}
                      borderRadius={6}
                    >
                      <h3
                        style={{
                          marginTop: "21px",
                          fontSize: "17px",
                        }}
                      >
                        {cri}
                      </h3>
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      value={cri}
                      onClick={() => handleTabChange(cri)}
                      p={2}
                      style={{
                        backgroundColor: "#d7ecff",
                        color: "white",
                        height: "40px",
                        margin: "10px",
                      }}
                      borderRadius={6}
                    >
                      <h3
                        style={{
                          marginTop: "21px",
                          fontSize: "17px",
                          color: "#084594",
                        }}
                      >
                        {cri}
                      </h3>
                    </Button>
                  )}
                </Grid>
              ))}
            </Grid>
          ) : (
            <Fragment></Fragment>
          )}
        </Grid>
        {selectedGroup == "INDIVIDUAL"
          ? getIndividualContent()
          : selectedGroup == "OVERALL"
          ? getOverallContent()
          : getTotalContent()}
      </div>
    );
  }
}

export default Dashboard;

import { Hidden } from "@mui/material";
import React, { Fragment, useState, useEffect } from "react";
import { Grid, Typography, Paper } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import { Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import { API_URL } from "../actions/auth";
import clsx from "clsx";

import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MailIcon from "@mui/icons-material/Mail";
import { useSelector, useDispatch } from "react-redux";
import AccountCircle from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import MessageIcon from "@mui/icons-material/Message";
import RestoreIcon from "@mui/icons-material/Restore";
import { loadUser, login } from "../actions/auth";
import ClassIcon from "@mui/icons-material/Class";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import SettingsIcon from "@mui/icons-material/Settings";
import SchoolIcon from "@mui/icons-material/School";
import DateRangeIcon from "@mui/icons-material/DateRange";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Snackbar from "@mui/material/Snackbar";
import Card from "@mui/material/Card";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { logout } from "../actions/auth";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router";
import Button from "@mui/material/Button";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Tooltip from "@mui/material/Tooltip";
import Slide from "@mui/material/Slide";
import { getNotifications } from "../actions/auth";
const drawerWidth = 210;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: "white",
  color: "#184e77",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    // marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& a": {
    color: "gray",
    textDecoration: "none",

    "&:hover": {
      textDecoration: "none",
    },
  },
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function Sidebar(props) {
  const [showSnack, setShowSnack] = useState(false);
  const [snack, setSnack] = useState("");
  const [showSnack2, setShowSnack2] = useState(false);
  const [snack2, setSnack2] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);

  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [openError, setOpenError] = useState(false);

  const [randomText, setRandom] = useState(makeid(4));

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const config = {
    headers: {
      Authorization: state.auth.token,
      "X-CSRFToken": state.auth.csrfToken,
      "Content-Type": "application/json",
    },
  };

  var name;
  switch (window.location.pathname) {
    case "/":
      name = "Hydrolabs Dashboard";
      break;
    case "/dashboards":
      name = "Hydrolabs Dashboard";
      break;
    case "/profiles":
      name = "Profiles";
      break;
    case "/new_entry":
      name = "Add new entry";
      break;
    case "/notifications":
      name = "Notifications";
      break;
    default:
      name = "Dashboard";
  }

  const menuId = "primary-search-account-menu";
  const notificationsMenuId = "primary-notifications-menu";

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Link
        style={{ textDecoration: "none", color: "black" }}
        to={"/dashboard"}
      >
        <MenuItem onClick={handleMenuClose}>Dashboard</MenuItem>
      </Link>
      <Link style={{ textDecoration: "none", color: "black" }} to={"/profiles"}>
        <MenuItem onClick={handleMenuClose}>Profiles</MenuItem>
      </Link>
      <Link
        style={{ textDecoration: "none", color: "black" }}
        to={"/new_entry"}
      >
        <MenuItem onClick={handleMenuClose}>New Entry</MenuItem>
      </Link>
      <Link
        style={{ textDecoration: "none", color: "black" }}
        to={"/view_data"}
      >
        <MenuItem onClick={handleMenuClose}>View Data</MenuItem>
      </Link>
      <Link style={{ textDecoration: "none", color: "black" }} to={"/logout"}>
        <MenuItem onClick={handleMenuClose}>Logout</MenuItem>
      </Link>
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <Link
        style={{ textDecoration: "none", color: "black" }}
        to={"/dashboard"}
      >
        <MenuItem onClick={handleMenuClose}>Dashboard</MenuItem>
      </Link>
      <Link style={{ textDecoration: "none", color: "black" }} to={"/profiles"}>
        <MenuItem onClick={handleMenuClose}>Profiles</MenuItem>
      </Link>
      <Link
        style={{ textDecoration: "none", color: "black" }}
        to={"/new_entry"}
      >
        <MenuItem onClick={handleMenuClose}>New Entry</MenuItem>
      </Link>
      <Link
        style={{ textDecoration: "none", color: "black" }}
        to={"/view_data"}
      >
        <MenuItem onClick={handleMenuClose}>View Data</MenuItem>
      </Link>
      <Link style={{ textDecoration: "none", color: "black" }} to={"/logout"}>
        <MenuItem onClick={handleMenuClose}>Logout</MenuItem>
      </Link>
    </Menu>
  );

  if (state.auth.alert) {
    setSnack2({
      title: state.auth.alert.title,
      message: state.auth.alert.message,
      type: state.auth.alert.type,
    });
    setShowSnack2(true);
    dispatch({ type: "REMOVE_ALERT" });
  }

  // if (!state.auth.isLoading) {
  //   if (state.auth.isAuthenticated) {
  return (
    <div style={{ zIndex: "99" }}>
      <CssBaseline />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openFeedback}
        onClose={() => setOpenFeedback(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openFeedback}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              backgroundColor: "white",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              padding: "20px",
              borderRadius: "6px",
            }}
          >
            <div style={{ marginTop: "-25px" }}>
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <IconButton size="small">
                  <CloseIcon
                    onClick={() => setOpenFeedback(false)}
                    style={{ color: "#184e77" }}
                  />
                </IconButton>
              </div>
              <h3>Welcome to Elemzy</h3>
              <p>
                Elemzy, being in the beta stage, may contain bugs or errors. We
                value your feedback to enhance the user experience
                <br></br>
                <br></br>
              </p>
              <br></br>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to={"/feedback_elemzy"}
                >
                  <Button
                    onClick={() => {
                      setOpenFeedback(false);
                    }}
                    style={{ height: "30px" }}
                    variant="contained"
                  >
                    Give Feedback
                  </Button>
                </Link>
                <Button
                  style={{ height: "30px", marginLeft: "10px" }}
                  variant="contained"
                  onClick={() => setOpenFeedback(false)}
                >
                  No
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openError}
        onClose={() => setOpenError(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openError}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              backgroundColor: "white",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              padding: "20px",
              borderRadius: "6px",
            }}
          >
            <div style={{ marginTop: "-25px" }}>
              <div
                style={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <IconButton size="small">
                  <CloseIcon
                    onClick={() => setOpenError(false)}
                    style={{ color: "#184e77" }}
                  />
                </IconButton>
              </div>
              <h3>Welcome to Elemzy</h3>
              <p>
                Elemzy, being in the beta stage, may contain bugs or errors. We
                value your feedback to enhance the user experience
                <br></br>
                Please report any errors that you might have experienced.
                <br></br>
                <br></br>
              </p>
              <br></br>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to={"/error_elemzy"}
                >
                  <Button
                    onClick={() => {
                      setOpenError(false);
                    }}
                    style={{ height: "30px" }}
                    variant="contained"
                  >
                    Report Errors
                  </Button>
                </Link>
                <Button
                  style={{ height: "30px", marginLeft: "10px" }}
                  variant="contained"
                  onClick={() => setOpenError(false)}
                >
                  No
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={6000}
        open={showSnack}
        onClose={() => setShowSnack(false)}
      >
        <Card style={{ minWidth: "350px", padding: "10px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex" }}>
              <NotificationsIcon
                style={{ color: "#184e77", fontSize: "44px" }}
              />
              <div style={{ marginLeft: "10px" }}>
                <b style={{ fontSize: "15px" }}>{snack["title"]}</b>
                <br></br>
                <span>{snack["message"]}</span>
              </div>
            </div>
            <IconButton size="small" onClick={() => setShowSnack(false)}>
              <CloseIcon style={{ color: "#184e77" }} />
            </IconButton>
          </div>
        </Card>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        autoHideDuration={6000}
        open={showSnack2}
        onClose={() => setShowSnack2(false)}
      >
        <Card style={{ minWidth: "350px", padding: "10px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex" }}>
              {snack2["type"] == "SUCCESS" ? (
                <CheckCircleIcon
                  style={{ color: "#2ca25f", fontSize: "44px" }}
                />
              ) : snack2["type"] == "MESSAGE" ? (
                <ErrorIcon style={{ color: "#184e77", fontSize: "44px" }} />
              ) : (
                <ErrorIcon style={{ color: "#ff3333", fontSize: "44px" }} />
              )}
              <div style={{ marginLeft: "10px" }}>
                <b style={{ fontSize: "15px" }}>{snack2["title"]}</b>
                <br></br>
                <span>{snack2["message"]}</span>
              </div>
            </div>
            <IconButton size="small" onClick={() => setShowSnack2(false)}>
              <CloseIcon style={{ color: "#184e77" }} />
            </IconButton>
          </div>
        </Card>
      </Snackbar>
      <AppBar
        position="fixed"
        elevation={5}
        open={open}
        sx={{ top: "auto", bottom: 0 }}
      >
        <Toolbar>
          <span
            style={{
              marginLeft: "16px",
              fontSize: "25px",
              marginTop: "3px",
              fontFamily: "Bahnschrift",
            }}
          >
            {name}
          </span>{" "}
          <Box sx={{ flexGrow: 1 }} />
          <div style={{ margin: "12.5px 17px 0px 10px", width: "50%" }}>
            {props.tabs}
          </div>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {/* <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  color="inherit"
                >
                  <Badge badgeContent={4} color="error">
                    <MailIcon />
                  </Badge>
                </IconButton> */}

            <Tooltip
              arrow
              // title={state.auth.user.firstname + " " + state.auth.user.lastname}
              placement="right"
            >
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <Avatar
                // alt={
                //   state.auth.user.firstname + " " + state.auth.user.lastname
                // }
                // src={API_URL + "/media/" + state.auth.user.user_image}
                />{" "}
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <Tooltip
              arrow
              // title={state.auth.user.firstname + " " + state.auth.user.lastname}
              placement="right"
            >
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <Avatar
                // alt={
                //   state.auth.user.firstname + " " + state.auth.user.lastname
                // }
                // src={API_URL + "/media/" + state.auth.user.user_image}
                />{" "}
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}

      {renderMenu}
    </div>
  );
  //   } else {
  //     return <Fragment></Fragment>;
  //   }
  // } else {
  //   return <Fragment></Fragment>;
  // }
}

export default Sidebar;
